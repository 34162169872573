<script>
  import { inertia } from '@inertiajs/svelte'
  
  export let flash
  export let current_user
  export let current_site
  export let errors
</script>

<svelte:head>
  {#if current_site}
    <base href="/sites/{current_site.id}/">
  {/if}
</svelte:head>

<nav class="flex flex-col">
  {#if current_site}
  <h1 class="p-4">
    {#if current_site.content.logo}
      <img src="/api/attachments/{current_site.content.logo}" alt="Logo" class="h-8" />
    {:else}
      {current_site.content.title}
    {/if}
  </h1>
  <ul class="grow">
    <li>
      <a href="?" use:inertia>
        <i class="i-bx:bar-chart-square text-size-6 align-mid relative -top-2px"></i>
        Übersicht
      </a>
      <a href="pages" use:inertia>
        <i class="i-bx-file text-size-6 align-mid relative -top-2px"></i>
        Seiten
      </a>
      <a href="navigations" use:inertia>
        <i class="i-bx-directions text-size-6 align-mid relative -top-2px"></i>
        Navigation
      </a>
      <a href="attachments" use:inertia>
        <i class="i-bx-image text-size-6 align-mid relative -top-2px"></i>
        Medienbibliothek
      </a>
      <a href="messages" use:inertia>
        <i class="i-bx-message text-size-6 align-mid relative -top-2px"></i>
        Anfragen
      </a>
      <a href="integrations" use:inertia>
        <i class="i-bx-link text-size-6 align-mid relative -top-2px"></i>
        Integrationen
      </a>
      <a href="users" use:inertia>
        <i class="i-bx-user text-size-6 align-mid relative -top-2px"></i>
        Benutzer
      </a>
      <a href="edit" use:inertia>
        <i class="i-bx-cog text-size-6 align-mid relative -top-2px"></i>
        Einstellungen
      </a>
    </li>
  </ul>
  {:else}
  <!-- <a href="/" use:inertia> -->
    
  <!-- </a> -->

  {/if}

  {#if current_user}
    <div class="w-full p-4">
      {current_user.name}<br>
      <a href="/session" use:inertia={{method: 'DELETE'}}>Log out</a>
    </div>
  {/if}
</nav>


<main>
  <div class="fixed z-1 bottom-4 right-4">
    {#if flash.success}
      <div class="flash success">
        {flash.success}
      </div>
    {/if}
    
    {#if flash.error}
      <div class="flash error">
        {flash.error}
      </div>
    {/if}
    
    {#if flash.notice}
      <div class="flash notice">
        {flash.notice}
      </div>
    {/if}

  </div>

  <slot />
</main>


<style>
  nav {
    position: fixed;
    top: 0;
    height: 100vh;
    width: 220px;
    background-color: white;
    border-right: 1px solid #e6e6e6; 
  }

  nav .logo {
    padding: 10px;
    width: 100%;
    display: inline-block;
  }

  nav ul {
    list-style-type: none;
    padding: 0;
  }

  nav ul a {
    text-decoration: none;
    display: block;
    padding: 10px;
    color: black;
  }

  nav ul a.active {
    color: white;
    background-color: black;
  }

  nav ul a:hover {
    background-color: #e6e6e6;
  }

  main {
    margin-left: 220px;
  }
  .flash {
    padding: 16px;
    margin-bottom: 16px;
  }
  .flash.success {
    background: #e6f7e6;
    border-left: 4px solid #00b300;
  }
  .flash.error {
    background: #f7e6e6;
    border-left: 4px solid #b30000;
  }
  .flash.notice {
    background: #f7f7e6;
    border-left: 4px solid #b3b300;
  }
</style>